import * as React from 'react'
import Layout from '../components/layout'
//import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = () => {
  return (
    <Layout pageTitle="Welcome:D">
      <p>こんにちは</p>
      <p>Hello, World. And I love you.</p>
    </Layout>
  )
}

export default IndexPage